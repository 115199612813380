<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('common.filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('common.company') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="companyFilter"
            :options="companyOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:companyFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('common.location') }}</label>
          <v-select
            :multiple="true"
            :value="objectFilter"
            ooptions="companyFilter? objectOptions[companyFilter]: objectOptions['all']"
            :options="objectOptions.filter(item => !companyFilter || item.company === companyFilter)"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:objectFilter', val)"
          />
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('common.machine') }}</label>
          <v-select
            ref="selDevicesMachine"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="machineFilter"
            :options="machineOptions.filter(item => (!companyFilter || item.company === companyFilter) && (!objectFilter.length || objectFilter.includes(item.object)))"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:machineFilter', val)"
          />
        </b-col>
        <!--b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col-->
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <!--          <ext-flat-pickr-->
          <!--            :value="rangePicker"-->
          <!--            @input="(val) => $emit('update:rangePicker', val)"-->
          <!--          />-->
          <ExtDatePicker
            :value.sync="range"
            class="mt-2"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import ExtFlatPickr from '@core/components/ext-flat-pickr/ExtFlatPickr.vue'
import ExtDatePicker from '@core/components/ext-date-picker/ExtDatePicker.vue'

export default {
  components: {
    ExtDatePicker,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    // ExtFlatPickr,
  },
  props: {

    companyFilter: {
      type: [Number, null],
      default: null,
    },
    objectFilter: {
      type: [Number, null],
      default: null,
    },
    machineFilter: {
      type: [Number, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    companyOptions: {
      type: Array,
      required: true,
    },
    machineOptions: {
      type: Array,
      required: true,
    },
    objectOptions: {
      type: Array,
      required: true,
    },
    /*
    statusOptions: {
      type: Array,
      required: true,
    },
    */
    rangePicker: {
      type: Array,
      required: true,
    },
  },
  computed: {
    range: {
      get() {
        return this.rangePicker
      },
      set(newValue) {
        this.$emit('update:rangePicker', newValue)
      },
    },
  },
  watch: {
    companyFilter(val) {
      this.$store.commit('appConfig/UPDATE_COMPANY_FILTER', val)
      if (!val) return
      if (this.objectFilter) {
        const item = this.objectOptions.find(obj => obj.value === this.objectFilter)
        if (item.company !== val) this.$refs.selDevicesLocation.clearSelection()
      }
      if (this.machineFilter) {
        const item = this.machineOptions.find(obj => obj.value === this.machineFilter)
        if (item.company !== val) this.$refs.selDevicesMachine.clearSelection()
      }
    },
    objectFilter(val) {
      this.$store.commit('appConfig/UPDATE_OBJECT_FILTER', val)
      if (!val) return
      if (this.machineFilter) {
        const item = this.machineOptions.find(obj => obj.value === this.machineFilter)
        if (item.object !== val) this.$refs.selDevicesMachine.clearSelection()
      }
    },
    machineFilter(val) {
      this.$store.commit('appConfig/UPDATE_MACHINE_FILTER', val)
    },
  },
  setup() {

    /* * /
    let companyOptions = ref([])
    store.dispatch('app-device/fetchCompanies')
            .then(response => {
              console.log('RESP', response.data)
              companyOptions = response.data.data
              console.log('companyOptions', companyOptions)
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error fetching companies list',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
    */

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
