import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import moment from 'moment'
import store from '@/store'
import i18n from '@/libs/i18n'

export default function useDevicesList() {
  // Use toast
  const toast = useToast()

  const refDeviceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'device_event_time',
      label: i18n.t('devices.time'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'device_event',
      label: i18n.t('devices.event'),
      formatter: title,
      sortable: true,
    },
    {
      key: 'machine_name',
      label: i18n.t('devices.machine'),
      formatter: title,
      sortable: true,
    },
  ]
  const perPage = ref(10)
  const totalDevices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 50, 250, 500]
  const searchQuery = ref('')
  const sortBy = ref('device_event_time')
  const isSortDirDesc = ref(true)

  // console.log('!!!', store.state.appConfig)
  const companyFilter = ref(store.state.appConfig.filters.company ? store.state.appConfig.filters.company : null)
  const objectFilter = ref(store.state.appConfig.filters.object ? store.state.appConfig.filters.object : [])
  const machineFilter = ref(store.state.appConfig.filters.machine ? store.state.appConfig.filters.machine : null)

  const statusFilter = ref(null)
  const rangePicker = ref([moment().set({
    hour: 0, minute: 0, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString(), moment().set({
    hour: 23, minute: 59, second: 0, millisecond: 0,
  }).format('YYYY-MM-DD HH:mm')
    .toString()])

  const dataMeta = computed(() => {
    const localItemsCount = refDeviceListTable.value ? refDeviceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDevices.value,
    }
  })

  const fetchDevices = (ctx, callback) => {
    store
      .dispatch('app-device/fetchDevices', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: machineFilter.value,
        company: companyFilter.value,
        object: objectFilter.value,
        status: statusFilter.value,
        dateRange: rangePicker.value, // .join(' '),
      })
      .then(response => {
        const { devices, total } = response.data
        if (callback) {
          callback(devices)
        }
        totalDevices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching devices list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    fetchDevices()
    refDeviceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, machineFilter, objectFilter, companyFilter, statusFilter, rangePicker], () => {
    refetchData()
  })

  const deleteDevice = item => {
    // console.log(Vue.swal)
    // console.log(store._vm)
    Vue.swal({
      title: 'Are you sure?',
      html: `Device <b>${item.name}</b> will be deleted. \nYou won't be able to revert this!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-device/deleteDevice', { id: item.id })
          .then(() => {
            refetchData()
            Vue.swal({
              icon: 'success',
              title: 'Deleted!',
              text: `Device ${item.name} has been deleted.`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Device delete error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  const objectOptions = ref([])
  store.dispatch('app-device/fetchObjects')
    .then(response => {
      // console.log('RESP', response.data)
      objectOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching roles list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const machineOptions = ref([])
  store.dispatch('app-device/fetchMachines')
    .then(response => {
      // console.log('RESP', response.data)
      machineOptions.value = response.data.data
      // console.log('roleOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching machines list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const companyOptions = ref([])
  store.dispatch('app-device/fetchCompanies')
    .then(response => {
      // console.log('RESP', response.data)
      companyOptions.value = response.data.data
      // console.log('companyOptions', companyOptions)
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching companies list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDeviceStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const resolveDeviceCompanyText = company => {
    if (!company) {
      return ''
    }
    try {
      return companyOptions.value.find(o => o.value === company).label
    } catch (e) {
      return 'undefined'
    }
  }

  const resolveDeviceMachineText = machine => {
    if (!machine) {
      return ''
    }
    try {
      return machineOptions.value.find(o => o.value === machine).label
    } catch (e) {
      return 'undefined'
    }
  }

  return {
    fetchDevices,
    tableColumns,
    perPage,
    currentPage,
    totalDevices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDeviceListTable,

    resolveDeviceStatusVariant,
    resolveDeviceCompanyText,
    resolveDeviceMachineText,
    refetchData,

    // Extra Filters
    machineFilter,
    companyFilter,
    objectFilter,
    statusFilter,

    companyOptions,
    objectOptions,
    machineOptions,

    deleteDevice,

    rangePicker,

  }
}
