import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDevices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/device/devices', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDevice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/device/devices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDevice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/device/devices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDevice(ctx, deviceData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/device/devices', { device: deviceData })
          .then(response => resolve(response))
          // eslint-disable-next-line
          .catch(error => {
            // console.log(error)
            // console.log(error.response)
            return reject(error.response)
          })
      })
    },
    fetchCompanies() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchObjects() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/object/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMachines() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/machine/filterdata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
